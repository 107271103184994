export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        id: 'desktop-index',
        name: 'Escritorio',
        to: '/home',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        id: 'mainmnu-index',
        _children: ['Menu Principal']
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"module-permission",
        name: 'Accesos',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            style: "display:none",
            id:"permission-index",
            name: 'Permisos',
            to: '/accesos/permisos'
          },
          {
            style: "display:none",
            id:"role-index",
            name: 'Tipo Usuario',
            to: '/accesos/tipos'
          },
          {
            style: "display:none",
            id: "user-index",
            name: 'Usuarios',
            to: '/accesos/usuarios'
          },  
          {
            style: "display:none",
            id: "permission-index",
            name: 'Permisos Asignados',
            to: '/accesos/permisos_asignados'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"module-maintenance",
        name: 'Mantenimiento',
        route: '/base',
        icon: 'cil-settings',
        items: [
          {
            style: "display:none",
            id: "client-index",
            name: 'Clientes',
            to: '/mantenimientos/clientes'
          },
          {
            style: "display:none",
            id: "drivers-index",
            name: 'Movilidad',
            to: '/mantenimientos/movilidad'
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"module-projects",
        name: 'Proyectos',
        route: '/base',
        icon: 'cil-location-pin',
        items: [
          {
            style: "display:none",
            id: "lotes-index",
            name: 'Lote',
            to: '/proyectos/lote'
          },
          {
            style: "display:none",
            id: "strettbook-index",
            name: 'Etapas/Manzanas',
            to: '/proyectos/etapa'
          },
          {
            style: "display:none",
            id: "project-index",
            name: 'Proyecto',
            to: '/proyectos/proyectos'
          },
        ]
      },
      {
        _name: '',
        id:"visits-index",
        name: 'Visitas',
        to: '/visitas',
        icon: 'cilCalendar'
      },
      {
        _name: '',
        id:"quotation-index",
        name: 'Cotización',
        to: '/cotizacion',
        icon: 'cilTask'
      },
      {
        _name: '',
        id:"myvisits-index",
        name: 'Mis visitas',
        to: '/misvisitas',
        icon: 'cilTask'
      },
      {
        _name: '',
        id:"purchase-index",
        name: 'Venta de lotes',
        to: '/ventalotes',
        icon: 'cilChartPie'
      },
      {
        _name: '',
        id:"charge-index",
        name: 'Cobros de lotes',
        to: '/cobros',
        icon: 'cilChartPie'
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"module-reports",
        name: 'Reportes',
        route: '/base',
        icon: 'cilList',
        items: [
          {
            style: "display:none",
            id: "report-visits-index",
            name: 'Reporte Visitas',
            to: '/reportes/reportes_visitas'
          },
          {
            style: "display:none",
            id: "report-quotations-index",
            name: 'Reporte Cotizaciones',
            to: '/reportes/reportes_cotizaciones'
          },
          {
            style: "display:none",
            id: "report-sales-index",
            name: 'Reporte Ventas',
            to: '/reportes/reportes_ventas'
          },
          {
            style: "display:none",
            id: "report-payments-index",
            name: 'Reporte Cobros',
            to: '/reportes/reportes_cobros'
          },
          {
            style: "display:none",
            id: "report-incomes-index",
            name: 'Reporte Ingresos',
            to: '/reportes/reportes_ingresos'
          },
        ]
      },
    ]
  }
]