<template>
  <CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/" style="padding:2px">
      <img src="../../public/logo.png" size="custom-size" :width="240">
      <!-- <CIcon class="c-sidebar-brand-full" name="logo" size="custom-size" :height="35" viewBox="0 0 556 134"/> -->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  created(){
    this.permisos();
  },
  methods:{
    permisos: function(){
      let array = [];
      array = this.$options.nav;
      let permisos = this.$store.getters.get__permissionxrol;
      let arr_permiso = [];

      permisos.forEach(element => {
        arr_permiso.push(element["name"]);
      });

      array.forEach(element => {
        element._children.forEach(submenu => {

          if(submenu.items === undefined){
            arr_permiso.forEach(permiso => {
              if(permiso === submenu.id){
                submenu._name = "CSidebarNavItem";
              }
            });

            if(submenu.id==="desktop-index"){
              submenu._name = "CSidebarNavItem";
            }
            else if(submenu.id==="mainmnu-index"){
              submenu._name = "CSidebarNavTitle";
            }
            
          }else{
            
            let row = submenu.items.length;
            let data_items = submenu.items;
            let num = 0;
            arr_permiso.forEach(permiso => {
                for (let index = 0; index < row; index++) {
                  if(permiso === data_items[index].id){
                    data_items[index].style = "display:block";
                  }
                }
            });

            data_items.forEach(dt_item => {
              if(dt_item["style"]==="display:none"){
                num++
              }
            });

            if(row == num){ submenu._name=""; }else{}
          }
        });
      });

      this.$options.nav = array;
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
