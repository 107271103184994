<template>
  <CFooter :fixed="false">
    <div>
      <a href="javascript:void(0)" target="_blank">MyDev's</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Inmobiliaria.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Desarrollado por </span>
      <a href="javascript:void(0)">MyDev's</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
